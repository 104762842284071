import { useState } from "react";
import toast from "react-hot-toast";
import { CiGift } from "react-icons/ci";
import { useSWRConfig } from "swr";
import { swrMainFetcher } from "../../../client/axios";
import { usePlatformRedirect } from "../../../modules/hook/usePlatformRedirect";
import useBoundStore from "../../../state/store";
import { getPriceWithComma } from "../../../utils/en2bn";
import { Offer } from "../types";
import { OfferCard } from "./offerCard";

export function YourOffer({
  offers = [],
  selectedOffer,
}: {
  offers?: Offer[];
  selectedOffer: string | null | undefined;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useSWRConfig();
  const { useStatus } = useBoundStore();
  const redirect = usePlatformRedirect();

  const handleApply = (offer_id: string) => {
    if (isLoading) return;
    setIsLoading(true);
    toast.promise(
      swrMainFetcher("/integration/iwoca/select_offer", "POST", {
        postBody: {
          offer_id,
        },
      }).finally(() => {
        setIsLoading(false);
      }),
      {
        loading: "Applying for loan...",
        success: (r: any) => {
          mutate("fetchUserinfo");
          redirect(r.login_link, true);
          return "Loan applied successfully";
        },
        error: (e) => `${e.message}`,
      }
    );
  };

  const handleApplied = () => {
    if (isLoading) return;
    setIsLoading(true);
    toast.promise(
      swrMainFetcher("/integration/iwoca/login").finally(() => {
        setIsLoading(false);
      }),
      {
        loading: "Preparing for iwoca...",
        success: (r: any) => {
          // mutate("fetchUserinfo");
          redirect(r.login_link, true);
          return "Redirecting to iwoca";
        },
        error: (e) => `${e.message}`,
      }
    );
  };

  if (offers.length === 0) {
    return (
      <section>
        <p className="font-bold">Your Offer</p>
        <div className="h-4"></div>

        <OfferCard
          offerTitle={"Loan Offers"}
          label={"iwoca"}
          contentText={`Get up to ${getPriceWithComma(
            "500000"
          )} for upcoming purchases`}
          role="offer"
          onClick={() => {
            if (useStatus?.score === 0) {
              toast.error("Please check your score first");
            } else {
              toast.error("Please apply for loan first");
            }
          }}
          icons={
            <p className=" absolute top-3 right-3 flex ">
              <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center">
                <CiGift size={25} />
              </div>
            </p>
          }
        />
      </section>
    );
  }
  return (
    <section>
      <p className="font-bold">Your Offer</p>
      <div className="h-4"></div>
      <section className="flex overflow-x-auto space-x-6 scroll-hide w-full pl-2">
        {offers.map((offer) => {
          const isSelected = selectedOffer === offer.offer_id;

          const canApply = !Boolean(selectedOffer);

          return (
            <OfferCard
              key={offer.offer_id}
              className={`min-h-[20vh] min-w-[70vw] shadow-lg rounded-xl border bg-card text-card-foreground  relative ${
                Boolean(canApply || isSelected) ? "" : "opacity-50"
              }`}
              offerTitle={`Loan up to ${getPriceWithComma(offer.max_amount)}`}
              label={`${offer.duration.amount} ${offer.duration.unit}`}
              contentText={`Get up to ${getPriceWithComma(
                offer.max_amount
              )} at ${offer.interest_rate}% interest rate for ${
                offer.duration.amount
              } ${offer.duration.unit}`}
              icons={
                <p className=" absolute top-3 right-3 flex ">
                  <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center">
                    <CiGift size={25} />
                  </div>
                </p>
              }
              role={isSelected ? "offer" : "new"}
              footer={
                canApply ? (
                  <div
                    onClick={() => handleApply(offer.offer_id)}
                    className="flex justify-end"
                  >
                    <div className="border-2 border-kGrey/30 px-2.5 py-1 text-sm rounded-lg">
                      Apply Now
                    </div>
                  </div>
                ) : isSelected ? (
                  <div className="flex justify-end" onClick={handleApplied}>
                    <div className="border-2 bg-kC2/20 px-2.5 py-1 text-sm rounded-lg">
                      Details
                    </div>
                  </div>
                ) : undefined
              }
            />
          );
        })}
      </section>
    </section>
  );
}
