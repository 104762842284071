import { Button, Modal } from "flowbite-react";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import giftAnim from "../../assets/lottie/gift.json";
import load from "../../assets/lottie/load.json";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../components/Card";
import TitlePageLayout from "../../components/Layouts/TitlePageLayout";
import SvgAnimation from "../../components/shared/SvgAnimation";
import { useRequestForMutation } from "../../modules/hook/useRequest";
import { TenorSelectionForCashLoan } from "../../modules/loan/pages/LoanProcess";
import { AsyncMutationRequest } from "../../modules/shared/AsyncValue";
import BtnPrimary from "../../modules/shared/BtnPrimary";
import { TermsWithExternalLink } from "../../modules/shared/TermsAndCondition.component";
import ThankYou from "../../modules/shared/ThankYou";

export const TenorAmountSelectionRouteName = "/tenor-amount-selection";

export interface ITenorAmountSelectionProps {}

const TenorAmountSelection: FC<ITenorAmountSelectionProps> = () => {
  const [tenor, setTenor] = useState<number>(6);
  const [amount, setAmount] = useState<number>(0);
  const [btnDisable, setBtnDisable] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState(false);
  const req = useRequestForMutation("/integration/iwoca/generate_offers");

  const handleAmount = () => {
    if (amount === 0) {
      toast.error("Please enter amount ");
      return;
    }

    setBtnDisable(true);

    if (!("trigger" in req)) return;
    req.trigger(
      {
        postBody: {
          amount: amount,
          tenor: tenor,
          privacy_policy_agreed: true,
        },
      },
      {}
    );

    // toast.promise(
    //   swrMainFetcher("/integration/iwoca/generate_offers", "POST", {
    //     postBody: {
    //       amount: amount,
    //       tenor: tenor,
    //       privacy_policy_agreed: true,
    //     },
    //   })
    //     .then((_) => {
    //       nav(-1);
    //     })
    //     .finally(() => {
    //       setTenor(6);
    //       setBtnDisable(false);
    //     }),
    //   {
    //     loading: "Generating your offer...",
    //     success: "Offer created successfully",
    //     error: (e) => `${e.message}`,
    //   }
    // );
  };

  return (
    <AsyncMutationRequest
      res={req}
      onLoading={
        <div className="min-h-screen flex flex-col items-center justify-center">
          <SvgAnimation animationData={load} />
          <div className="h-3"></div>
          <p className="text-gray-500 text-medium">
            Hang tight, we're getting things ready for you!
          </p>
        </div>
      }
      onIdle={
        <TitlePageLayout title="Apply for Loan" dontShowBottomNav>
          <section className="m-4 ">
            <div className="h-[3vh]"></div>
            <Card>
              <CardHeader>
                <h3 className="text-center">Congratulations!</h3>
              </CardHeader>
              <CardContent>
                <SvgAnimation animationData={giftAnim} loop={3} />
              </CardContent>
              <CardFooter>
                <div className="w-full">
                  <p className="text-base text-kGrey text-center">
                    Choose your loan amount and duration to proceed with your
                    application from iwoca.
                  </p>
                </div>
              </CardFooter>
            </Card>

            <div className="h-2"></div>
            <TenorSelectionForCashLoan
              options={[6, 9, 12, 24]}
              onChange={function (val: number): void {
                setTenor(val);
              }}
              setValue={setTenor}
            />
            <div className="h-4"></div>
            <p className="font-semibold mb-2">Amount</p>
            <div className="flex justify-center">
              <input
                type="number"
                className="w-full p-2 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-purple-500 transition-all duration-300 ease-in-out"
                placeholder={`Amount you'd like to borrow`}
                onChange={(e) => setAmount(parseInt(e.target.value))}
              />
            </div>
            <div className="h-4"></div>
            <TermsWithExternalLink
              url={"/integration/iwoca/terms"}
              onChange={(e) => {
                setBtnDisable(!e.target.checked);
              }}
            />
            <div className="h-4"></div>

            <BtnPrimary
              center
              onClick={() => setOpenModal(true)}
              disable={btnDisable || amount === 0}
              className="btn w-full bg-kGreen-light"
            >
              Submit
            </BtnPrimary>

            <Modal show={openModal} onClose={() => setOpenModal(false)}>
              <Modal.Header>Terms of Service</Modal.Header>
              <Modal.Body>
                <div className="space-y-6">
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    We plan to show you credit offers from carefully selected
                    providers. These providers will share your information with
                    credit reference agencies to run soft credit and identity
                    checks, but don't worry this will not affect your credit
                    score.
                  </p>
                  <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    They will also share it with fraud prevention agencies -
                    please be aware, if they do spot fraud, you or others could
                    be refused services, finance, or employment.
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button color={"success"} onClick={handleAmount}>
                  I accept
                </Button>
                <Button color="secondary" onClick={() => setOpenModal(false)}>
                  Decline
                </Button>
              </Modal.Footer>
            </Modal>
          </section>
        </TitlePageLayout>
      }
      onSuccess={function (data: unknown): JSX.Element {
        return (
          <div className="">
            <div className="h-[20vh]"></div>
            <ThankYou>
              <p className="text-gray-700 mx-6 text-center">
                You have been approved for a loan with iwoca! Click below to
                view your offers.
              </p>
              <div className="h-4"></div>
              <BtnPrimary routeName="/home">View Offers</BtnPrimary>
            </ThankYou>
          </div>
        );
      }}
    />
  );
};
export default TenorAmountSelection;
